.fullscreen > .description {
  position: fixed;
  width: 15vw;
  height: 90vh;
  padding: 20px;
  right: 20px;
  top: 20px;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
}

.profile-btn-show{
  background-color: red;
}

.fullscreen {
  margin: 0;
}

.close {
  text-align: left;
}

.description {
  text-align: center;
  width: 100%;
  display: block;
}

.description .time {
  text-align: right;
  width: 100%;
  /* position: absolute; */
}

.description .attribute {
  font-weight: bold;
  margin: 10px auto;
}

.description p {
  margin: 0;
  margin-top: 15px;
}
.description h3 {
  margin: 0;
}

.post {
  overflow: hidden;
  display: flex;
  height: 300px;
  justify-content: center;
  position: relative;
  margin-top: 20px;
  border-radius: 8px;
  background: #ccc;

  .picture[fullscreen="true"] {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0;
    top: 0;
    z-index: 107;
    display: flex;
    justify-content: center;
    object-fit: contain;

    .caption {
      position: absolute;
      bottom: 10px;
      width: 50%;
      background-color: white;
      border-radius: 5px;
      padding: 10px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
}

.post:hover {
  border: 2px solid #999;
}
.fullscreen {
  margin-top: 0;
}
.fullscreen:hover {
  border: none;
}

.toast {
  position: fixed;
  bottom: 30px;
  left: 50px;
  background-color: #eee;
  border: 3px solid black;
  padding: 30px;
  width: 20%;
  overflow-x: auto;
}

.post img[developer="true"] {
  opacity: 0.05;
}

.close-fullscreen {
  position: fixed;
  top: 10px;
  left: 30px;
  color: white;
}

.boosted {
  border: 5px #00b0ff solid;
}

.boosted-img {
  border: 5px #00b0ff solid;
}

.safe-boost {
  outline: 3px black dashed;
}

.controls {
  .control {
    padding: 10px;
    button {
      width: 40px;
      height: 40px;
      box-shadow: 0 2px 10px var(--bluegray-100), 0 2px 2px var(--bluegray-300);
    }
  }
}


// .fullscreen .picture {
//   position: fixed;
//   width: auto;
//   top: 52px;
//   height: 68vh;
//   padding: 20px;
//   box-sizing: border-box;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }