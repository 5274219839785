.container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.full-screen {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(255,255,255,0.95);
    z-index: 999;
}

.active-post{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-top:3px;
    padding-bottom:3px;
    gap:20px;
    height:100%;
    width:100%;
}

.details{
    width: 80vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.active-post-img{
    display: flex;
    align-items: center;
    justify-content: center;
}

.active-post-img img{
    object-fit: fill;
    max-height: 75vh;
    max-width: 55vw;
    border-radius: 10px;
}

.active-post-controls{
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap:20px
}
