.verification-posts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.verification-posts>div {
  width: 17%;
  padding: 10px;
}

.verification-posts>.post {
  overflow: hidden;
  display: flex;
  height: 300px;
  position: relative;
  margin-top: 20px;
  border-radius: 8px;
  background: #999;
}

.verification-posts .fullscreen {
  display: none;
}

.active .post {
  display: none;
}

.active .fullscreen {
  display: flex;
  justify-content: center;
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  height: 100vh;
  width: 100%;
  z-index: 100;
  top: 0;
  right: 0;
}

.custom-button {
  height: 30px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  background-color: white;
  color: black;
  border-radius: 10px;
  border:none;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  transition: background-color 0.15s;
}

.custom-button:hover {
  background-color: lightgray;
  border: 0.5px solid gray;
}


.verification-posts .fullscreen img {
  position: relative;
}

.verification-posts .center {
  display: flex;
  justify-content: space-around;
  width: 50%;
}

.verification-posts .center img {
  max-width: 50%;
}

.verification-counter {
  background: white;
  position: absolute;
  width: 50px;
  margin: 10px;
  padding: 10px;
  font-size: 20px;
  text-align: center;
}

.pagination {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: stretch;
  width: 800px;
}

.pagination>div {
  font-size: 20px;

  background: white;
  margin: 10px;
  padding: 10px;
}

.ai_info {
  margin-left: 5.5vw;
  width: 220px;
  font-size: 22px;
  margin-right: 20vw;
  border-radius: 10px; /* Small rounded corners */
  overflow: hidden; /* Ensures the border-radius is applied to the table as well */
}

.info_description {
  min-width: 25vw; /* Maximum width */
  width: auto; /* Width adjusts to content */
  text-align: center; /* Centers the text within the div */
  background: rgba(209, 209, 209, 0.98);
  display: flex;
  flex-direction: row;
  justify-content: center; /* Horizontally centers the contents */
  align-items: center; /* Vertically centers the contents within the div */
}

.info {
  display: flex;
  flex-direction: row;
  /* gap: 25vw; You might want to adjust this gap as well */
  position: fixed;
  top: 68vh;
  padding: 10px;
  font-size: 23px;
  width: 100%; /* Ensures that the info bar spans the whole width */
  align-items: center; /* Aligns children vertically center */
}

.red_match{
  color:red;
}

/* this is for description in verif and histverif */
@media only screen and (max-height: 700px) {
  .info {
  top: 60vh;
  }
  .ai_info{
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1400px) {
  .ai_info{
    font-size: 19px;
    width: 170px;
  }
}


.verification-image {
  max-width: 100%;
  max-height: 40vh;
  margin: auto;
  display: block;
  position: relative !important;
}

.verification-posts>.controls {
  width: 100%;
}

.images {
  width: 90%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.images img {
  flex-grow: 2;
  padding: 10px;
}

.images .ref {
  max-height: 50%;
  flex-grow: 1;
}


/* // */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.swiping_div {
  position: absolute;
  right: 26px;
  top: 84px;
}