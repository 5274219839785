.fullscreen>.description {
    position: fixed;
    width: 15vw;
    height: 90vh;
    padding: 20px;
    right: 20px;
    top: 20px;
    border-radius: 8px;
    overflow-x: hidden;
    overflow-y: auto;
}

.fullscreen {
    margin: 0;
}

.close {
    text-align: left;
}

.description {
    text-align: center;
    width: 100%;
    display: block;
}

.description .time {
    text-align: right;
    width: 100%;
    /* position: absolute; */
}

.description .attribute {
    font-weight: bold;
    margin: 10px auto;
}

.description p {
    margin: 0;
    margin-top: 15px;
}

.description h3 {
    margin: 0;
}

.post {
    overflow: hidden;
    display: flex;
    height: 300px;
    justify-content: center;
    position: relative;
    margin-top: 20px;
    border-radius: 8px;
    background: #ccc;

    .picture[fullscreen="true"] {
        position: fixed;
        height: 100vh !important;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 0;
        top: 0;
        z-index: 99999999;
        display: flex;

        .caption {
            position: absolute;
            bottom: 10px;
            width: 50%;
            background-color: white;
            border-radius: 5px;
            padding: 10px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
    }
}

.post:hover {
    border: 2px solid #999;
}

.fullscreen {
    margin-top: 0;
}

.fullscreen:hover {
    border: none;
}

.toast {
    position: fixed;
    bottom: 30px;
    left: 50px;
    background-color: #eee;
    border: 3px solid black;
    padding: 30px;
    width: 20%;
    overflow-x: auto;
}

.post img[developer="true"] {
    opacity: 0.05;
}

.close-fullscreen {
    position: fixed;
    top: 10px;
    left: 30px;
    color: white;
}

.boosted {
    border: 5px #00b0ff solid;
}

.boosted-img {
    border: 5px #00b0ff solid;
}

.safe-boost {
    outline: 3px black dashed;
}

.controls {
    .control {
        padding: 10px;

        button {
            width: 40px;
            height: 40px;
            box-shadow: 0 2px 10px var(--bluegray-100), 0 2px 2px var(--bluegray-300);
        }
    }
}

// bio scss
.static-bio {
    display: flex;
    align-items: center;
    gap: 80px;
    max-width: 100%;
    border-radius: 8px;
    height: 100%;
}

.static-bio .bio-desc {
    max-width: 350px;
    border: 1px solid #80808085;
    padding: 10px;
    border-radius: 8px;
    font-weight: 700;
    max-height: 200px;
    height: 100%;
    overflow-y: auto;
    background-color: #fff;
}

.static-bio .bio-desc p {
    margin: 0;
    font-size: 20px;
}


// swiping/profile 
// .static-bio-profile {
//     display: grid;
//     grid-template-columns: repeat(4, 1fr); // creates two columns with equal width
//     gap: 16px; // sets the gap between grid items
//     justify-items: center; // centers items along the row axis
//     align-items: center; // centers items along the column axis

//     img {
//       width: 70%; // makes the image responsive to the container width
//       height: auto; // maintains aspect ratio
//       object-fit: contain; // ensures the image is fully visible within the frame
//       border-radius: 8px; // optional: adds rounded corners to the images
//       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // optional: adds a subtle shadow to the images

//       &.boosted-img {
//         border: 3px solid gold; // styles for boosted images
//       }
//     }

//     .row {
//       display: contents; // allows the images to be direct children of the grid container
//     }

//     h3 {
//       text-align: center;
//       margin: 0;
//     }
//   }

.card-imag-main {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    // border: 5px solid black;
    height:535px;
}

.card-bg-image {
    display: flex;
    justify-content: center; /* Centers image horizontally */
    align-items: center;
    border-radius: 25px;
    // padding:2px;
    // border: 5px solid black;
    overflow: hidden;
    width: auto;
    height: auto;
    max-width: 300px;
    max-height: 600px;
    // margin-top: 80px;
    position: relative;


    flex-basis: calc(50% - 10px); /* Adjusts for 2 images per row with a small gap */
    // margin: 5px;
    
}

.card-bg-image img {
    max-width: 100%;
    padding:0 20px;
    object-fit: contain !important; /* Ensures the entire image is visible without cropping */
}

.title-box {
    background: transparent !important;
    box-shadow: none !important;

}

.title-box h2 {
    margin: 0px !important;
}

.title-box p {
    color: #c5c5c5 !important;
    margin: 0px !important;
    font-size: 18px;
    font-weight: 700;
}

.bottom-btn {
    border: 1px solid #ececed !important;
    // padding: 5px 2px 4px;
    border-radius: 35px;
    color: #000;
    font-size: 18px;
    font-weight: 600;
    background-color: white;
    margin-right: 15px;
    height: 70px;
    width: 100px;
}

/* Additional styles for disabled state */
.bottom-btn.disabled {
    color: #000;
    /* Grey text to indicate it's disabled */
    border: 1px solid #a0a0a0;
    /* Grey border for disabled state */
    background-color: #f0f0f0;
    /* Light grey background for disabled state */
    // cursor: not-allowed; /* Cursor to indicate the button is not clickable */
}

.bottom-btn.disabled p {
    color: #a0a0a0;
    /* Grey text for the letter as well */
}

.bottom-btn p {
    font-size: 12px !important;
    color: #000 !important;
    text-align: center !important;
    padding-top: 5px;
    font-weight: 600;
    margin: 0;
}

.image-title {
    position: absolute;
    top: 10px;
    width: 100%;
}

.image-title p {
    color: #d1dee2;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    margin: auto;
}

.align-item-center {
    align-items: center;
}

.gap-3 {
    gap: 13px;
}