.developer {
  padding: 20px;
}

.p-tabview {
  /* background-color: #fff; */
}

.p-tabview-nav {
  display: flex;
}

.p-tabview-nav {
  list-style: none;
}

.message {
  margin: 10px;
}

.message .p-card-content .p-d-flex {
  display: flex;
  justify-content: space-between;
}
