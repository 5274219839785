.hide {
    display: none;
}

/* .controls .control:nth-child(2){
 display: none;
} */

.clsbtn{
    background-color: "white";
    border: "1px solid lightgray";
    border-radius: "10px";
    padding: "10px";
    font-weight: "bold";
    font-size: "15px";
    z-index: "999"
}

.profile-btn-show {
    padding: 8px 16px !important;
    height: 120px !important;
    margin: 0px 15px;
    border-radius: 25px;
    color: #000 !important;
    background-color: #F8F8F8 !important;

}

.profile-btn-show svg {
    font-size: 40px;
    background: transparent !important;
    font-weight: 100;
}

.profile-btn-show button {
    background: transparent !important;
    box-shadow: none !important;
    width: auto;
}

.profile-btn-show .description {
    color: #000 !important;
    font-weight: 600;
    font-size: 14px;
}

.control .letter {
    order: 1 !important;
}