.fullscreen>.description {
  position: fixed;
  width: 15vw;
  height: 90vh;
  padding: 20px;
  /* padding-top: 30px; */
  right: 20px;
  top: 20px;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
}

.fullscreen .left-panel {
  position: fixed;
  width: 15vw;
  height: 90vh;
  padding: 20px;
  left: 20px;
  top: 40px;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.8);
  color: white;
}

.fullscreen {
  margin: 0;
}

.close {
  text-align: left;
}

.description .time {
  text-align: right;
  width: 100%;
  /* position: absolute; */
}

.description .attribute {
  font-weight: bold;
  margin: 10px auto;
}

.description p {
  margin: 0;
  margin-top: 15px;
}

.description h3 {
  margin: 0;
}

.post {
  overflow: hidden;
  display: flex;
  height: 300px;
  justify-content: center;
  position: relative;
  margin-top: 20px;
  border-radius: 8px;
  background: #ccc;
}

.post:hover {
  border: 2px solid #999;
}

.fullscreen {
  margin-top: 0;
}

.fullscreen:hover {
  border: none;
}

.toast {
  position: fixed;
  bottom: 30px;
  left: 50px;
  background-color: #eee;
  border: 3px solid black;
  padding: 30px;
  width: 20%;
  overflow-x: auto;
}

.left-panel .row {
  display: block;

  justify-content: space-between;
  padding: 5px;
  margin-bottom: 10px;
}

.left-panel .row>div {
  margin-bottom: 5px;
}

.post img[developer="true"] {
  opacity: 0.05;
}

.editText {
  position: fixed;
  top: 76%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
  overflow-x: auto;
  color: red;
  font-weight: bold;
}