* {
  box-sizing: border-box;
}

html,
body {
  background: #eee;
  margin: 0;
  padding: 0;
}

.root {
  width: 100%;
  height: 100%;
}

p {
  text-align: left;
}

.post-result {
  position: relative;
}

.post-result .btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  background: #e5e5e5;
  padding: 10px 30px;
  color: #666;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.page-navigation {
  width: 50%;
  display: flex;
  justify-content: space-around;
  margin: 10px auto;
  position: relative;

}

.page-navigation>div {
  background: white;
  border: 1px solid lightgray;
  padding: 10px;
}

.page-navigation-btn {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 10px auto;
  position: relative;

}

.page-navigation .paginate-btn {
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  column-gap: 30px;
  width: 50%;
}

.page-navigation-btn .paginate-btn {
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  /* column-gap: 30px; */
  width: 50%;
}

.count {
  position: absolute;
  left: 50px;
  background: white;
  border: 1px solid lightgray;
  padding: 10px;
}

.notification {
  width: 75%;
  padding: 20px;
  font-weight: bolder;
  margin: 10px auto;
  text-align: center;
  background: white;
  color: red;
}

.h-100 {
  height: 100vh;
}

.posts {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.posts>div {
  width: 17%;
  padding: 10px;
}

.close {
  position: absolute;
  top: 15px;
  left: 20px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  z-index: 999
}

.close:hover {
  color: white;
  background-color: darkgray;
}

.post>.description {
  position: absolute;
  border: 1px solid black;
  padding: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  bottom: 0;
}

.fullscreen .post {
  background: none;
}

.posts>.fullscreen {
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  height: 100vh;
  width: 100%;
  z-index: 100;
  top: 0;
  right: 0;
}

.search {
  width: 50%;
}

.controls {
  height: 125px;
  z-index: 105;
  position: fixed;
  bottom: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  /* padding-right: 60px; */
}

.hidden {
  display: none;
}

.fullscreen .hidden {
  display: block;
}

.fullscreen .controls {
  display: flex;
}

.fullscreen .controls {
  display: flex;
}

.fullscreen .controls.hidden {
  display: none;
}

.control {
  background: white;
  /* border: 1px solid black; */
  box-shadow: 1px 1px 5px grey;
  padding: 20px;
  max-width: 15%;
  margin: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.control .letter {
  /* border: 1px solid black; */
  text-transform: uppercase;
  color: #888;
  font-weight: 500;
  margin: 10px auto;
}

.control .description {
  color: #555;
  /* border: 1px solid black; */

}

.control button {
  /* border: 1px solid black; */

  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  box-shadow: -2px -2px 15px grey;
}

.control .clicked,
.control button:active {
  box-shadow: 2px 2px 15px grey;
}

*:focus {
  outline: none;
}

.selected {
  border: 5px solid red;
}

.fullscreen .picture {
  /* maxbP7oOgigrixBTrKBITY49 */
  /* max-width: 40vw; */
  /* border:1px solid black; */
  position: fixed;
  column-gap: 20px;
  top: 50px;
  height: 70vh;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  /* width: 416px;
    height: 694px; */
}



.picture img {
  max-width: 100%;
  border-radius: 8px;
  height: 85%;
  object-fit: contain;
}

.postText {
  position: fixed;
  top: 85vh;
  /* border: 1px solid black; */

  /* margin-top: 20px; */
  border-radius: 5px;
  padding: 10px;
  background: rgba(250, 250, 250, 0.9);
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 106;
}

.postText_history {
  position: fixed;
  top: 74.5vh;
  /* border: 1px solid black; */

  /* margin-top: 20px; */
  border-radius: 5px;
  padding: 10px;
  background: rgba(250, 250, 250, 0.9);
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 106;
}

.postText_history_review {
  position: fixed;
  top: 78vh;
  border-radius: 5px;
  padding: 10px;
  background: rgba(250, 250, 250, 0.9);
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 106;
}

.postText_allPosts {
  position: fixed;
  top: 75vh;
  /* left: 30%; */
  border-radius: 5px;
  padding: 10px;
  background: rgba(250, 250, 250, 0.9);
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 106;
  
  /* Flexibility for text content */
  max-height: 200px; /* Adjust this value as needed */
  overflow: auto; /* Adds a scrollbar if the content overflows */
  text-overflow: ellipsis;
  white-space: normal; /* Allows text to wrap */
}


.postText_profile {
  position: fixed;
  top: 72vh;
  border-radius: 5px;
  padding: 10px;
  background: rgba(250, 250, 250, 0.9);
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 106;
}

.postText_profile_h {
  position: fixed;
  top: 68vh;
  border-radius: 5px;
  padding: 10px;
  background: rgba(250, 250, 250, 0.9);
  background: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  z-index: 106;
}

.postText_profile_history {
  position: fixed;
  top: 76vh;
  left: 41.25vw;
  display: flex;
  flex-direction: column;
  gap:15px;
  align-items: flex-start;
}

.block {
  background: rgba(256, 256, 256, 0.9);
  box-shadow: 5px 5px 15px gray;
  padding: 20px;
  margin-top: 20px;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.p-2 {
  padding: 20px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.input,
.p-inputtext {
  width: 100%;
  margin: auto;
}

.btn {
  background: #bbb;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 20px;
  margin: auto;
  width: 20%;
}

.btn:active {
  padding: 12px 22px;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: white;
  margin: 0;
  padding: 10px;
  padding-right: 50px;
}

nav>div,
nav>a {
  padding: 5px;
  margin: 5px;
  color: inherit;
  text-decoration: inherit;
}

nav>.active {
  border-bottom: 3px solid black;
}

nav>.right {
  text-align: right;
  display: flex;
  align-items: center;
  gap: 5px;
  position: absolute;
  right: 20px;
}

.w-100 {
  width: 100%;
}

.w-100 .p-inputtext {
  width: 100%;
}

.p-button.p-button-outlined {
  background: white;
}


/*  */
.user-data-id {
  word-break: break-all;
}

.img-circle {
  max-width: 100%;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin: 25px auto;
  object-fit: cover;
}

.picture .chat-div {
  padding: 20px;
  width: 700px;
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 40px;
  background-color: #f0f0f0;

}

.picture .chat-div .chater {
  background-color: #fff;
  padding: 15px;
  width: fit-content;
  margin-bottom: 15px;
  border-radius: 15px;
}

.picture .chat-div .chat-width {
  width: 70%;
}

.picture .chat-div .chat-width.chat-width2 {
  margin-left: auto;
}

.picture .chat-div .chater p {
  margin: 0;
  word-break: break-all;
  white-space: pre-wrap;
  width: fit-content;
}

.picture .chat-div .chater.chater2 {
  margin-left: auto;
}

.picture .report-text p {
  margin-top: 0;
  font-size: 18px;
}

.description.description-add {
  left: 20px;
  right: auto;
  top: 50px !important;
}

.description.description-right {
  top: 50px !important;
}

.description.description-right p b,
.description.description-add p b {
  font-size: 25px;
}

.w-100 {
  width: 100%;
}

.description.description-add .table-chat {
  border: 1px solid #000;
  width: 100%;
  padding: 10px;
}

.description.description-add .table-chat .user-get .user-get-text {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.description.description-add .table-chat .user-get .user-get-text h4,
.description.description-add .table-chat .user-get .user-get-text p {
  margin: 0;
  word-break: break-all;
}

.description.description-add .table-chat .user-get .user-get-text .w-fix {
  width: 100px;
}

.w-100 h4 {
  text-align: left;
}

.mt-4 {
  margin-top: 20px;
}

.fullscreen.fullscreen-white {
  background-color: #ffff;
}

.p-wrap {
  word-break: break-all;
  padding: 0 5px;
}

.id-fixed {
  position: fixed;
  bottom: 40px;
  right: 40px;
  color: lightgray;
}

.id-fixed-top {
  position: fixed;
  top: 40px;
  right: 40px;
  color: lightgray;
}

.english {

  position: fixed;
  width: auto;
  top: 448px;
  left: 10%;
  height: 70vh;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;

}

.translating {
  max-width: 350px;
  border: 1px solid #80808085;
  padding: 10px;
  border-radius: 8px;
  font-weight: 700;
  max-height: 200px;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  left: 73px;
  top: 89px;
  position: absolute;
}

.isfullpic {
  /* position: fixed;
     width: auto; */
  top: 52px;
  height: 68vh;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}


.elementstyle {
  position: absolute;
  bottom: 19vh;
  width: 100%;
  text-align: center;
  background: black;
  color: white;
}

#btns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: lightgray;
  margin-left: 50px;
  border-radius: 10px;
  cursor: pointer;
  /* max-width:150PX ; */
}

#btns:hover {
  background-color: rgb(124, 121, 121);
  color: whitesmoke;
}

.new_profile_img {
  height:100%;
  width: 100%;
  /* height: 200px; */
  border-radius: 5px;

}

.new_profile_div {
  /* position: fixed;
  column-gap: 20px;
  height: 68vh;
  padding: 20px;
  box-sizing: border-box;
  display: flex; */
  
 /* ******************** */
  column-gap: 20px;
  /* border: 1px solid black; */
  height: 34vh;
  width: 13vw;
  position: fixed;
  right: 23vw;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  top: 35.35vh;
}

.new_profile_div_fullScreen {
  /* column-gap: 20px; */
  /* border: 1px solid black; */
  /* height: 34vh; */
  width: 13vw;
  position: fixed;
  right: 23vw;
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  top: 10vw;
}
  
.new_profile_bio {
  background-color: white;
  color: #000;
  display:flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  /* gap:0; */
  /* padding: 0; */
  padding: 5px;
  border-radius: 5px;
}


.full_profile {
  width: 27vw !important;
  /* height: 27vw !important;
    border-radius: 10px !important;
    overflow: hidden !important; */
  position: absolute !important;
  right: 3vw !important;
  display: flex !important;
  /* column-gap: 20px;  */
  top: 18vh !important;
  padding-top: 20px !important;
  z-index: 999 !important;
  flex-direction: column !important;
}

.new_verify_div {
  left: 23vw;
  top: 32vh;

}

.new_verify_div_fullScreen {
  left: 23vw;
  top: 5vw;

}

.full_verify_img {
  left: 3vw !important;
  top: 0vw !important;
}

.demo {
  position: absolute;
  z-index: 999;
  max-width: 400px;
  max-height: 650px;
  overflow: auto;
  top: 151px;
  left: 18px;
}

.black-input {
  height: fit-content;
  position: absolute;
  /* margin-right:25vw; */
  right:10vw;
  bottom: 9vw;
  /* background: black; */
  z-index: 9999;
}

.gender-change {
  position: absolute;
  right: 28px;
  top: 72px;
  display: flex;
  column-gap: 20px;
}

@media (max-width: 845px) {
  .gender-change {
    top: 90px;
  }
}

.gender-change button {
  background-color: #bbb;
  border: none !important;
  color: #ffffff;
  /* background: #7B95A3;
  border: 1px solid #7B95A3; */
  padding: 0.429rem 1rem;
  font-size: 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 2px;
  cursor: pointer;
}

.gender-change button:hover {
  background: #777 !important;
}

.spinner-profile .picture {
  justify-content: center !important;
}

.spinner-profile {
  background: rgba(255, 255, 255, 0.94) !important;
}

.loader {
  top: 0;
  right: 0;
  position: absolute;
}

.image-container {
  position: relative;
  display: inline-block;
}

.cross-image {
  position: absolute;
  bottom: 14px;
  right: 24px;
  color: red;
  /* Set the color of the X */
  font-size: 17px;
  /* Set the font size of the X */
}