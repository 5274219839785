.history {
  width: 100%;
  padding: 20px;

  .buttons {
    position: absolute;
    bottom: 0;
    width: 40%;
    display: flex;
    justify-content: center;
    max-height: 20vh;

    .control {
      padding: 10px;
      display: flex;
      align-items: center;

      button {
        width: 40px;
        height: 40px;
        box-shadow: 0 2px 10px var(--bluegray-100),
          0 2px 2px var(--bluegray-300);
      }
    }

    &.many {
      .control {
        padding: 5px;

        .letter {
          margin: 5px auto;
          font-size: 14px;
        }

        .description {
          font-size: 14px;
        }
      }
    }
  }

  .filters {
    display: flex;
    justify-content: center;
  }


  .admin-filter {
    display: flex;
    position: absolute;
    right: 20px;
    max-width: 20%;
    flex-direction: column;
    top: 134px;
  }

  .admin-filter .p-dropdown {
    width: 100%;
  }

  .action-filter {
    position: relative;
    text-align: center;
    bottom: 1px;
    left: 45px;
  }

  .action-filter .p-dropdown {
    // border: solid 3px #98a8af;
    width: 149px;
    height: 37px;
  }
  .action-filter .p-dropdown .p-dropdown-trigger {
    display: none; 
  }
  
  .action-filter .p-dropdown .p-dropdown-clear-icon {
    display: none; 
  }
  
  
}