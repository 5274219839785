.p-button {
  background-color: #bbb;
  border: none !important;
}
.p-button:hover {
  background: #777 !important;
}

.p-progress-spinner-container {
  height: 100vh;
  width: 100vw;
  text-align: center;
  display: flex;
  align-items: center;
  margin: auto;
}
